type TWebLinks = {
  [key: string]: string;
};

export const webLinks: TWebLinks = {
  "acceso-sanitizante": "https://accesosanitizante.com/",
  "aerotec-digital": "/aerotec-digital",
  "aerotec-sureste": "https://aerotecsureste.com/",
  itoy: "https://itoy.com.mx/",
  powerfan: "https://www.power-fan.com/",
  "publicidad-aerea": "https://publicidadaerea.com.mx/",
  "uniformes-para-edecan": "https://uniformesparaedecan.com/",
};
