import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import Link from "../components/lib/customLink";
import Layout from "../components/layout/Layout";
import { FaEnvelope } from "react-icons/fa";
import { MdSmartphone } from "react-icons/md";
import ContactoIll from "../components/SVG/contacto-ill/contacto";
import GrupoAerotecLogos from "../components/themed/grupoAerotecLogos";
import { ContactForm } from "../components/themed/contactForm";
import CatalogoForm from "../components/themed/catalogoForm";
import { Vendedor } from "../components/themed/contactPageComponents/Vendedor";
import { ContactoLink } from "../components/themed/contactPageComponents/ContactoLinkButton";
import { generateVendedor } from "../data/contacto-data";
import axios from "axios";
import { VendedorProps } from "../components/themed/contactPageComponents/types";
import { formatPhoneNumber } from "../helpers/formatPhoneNumber";

const ContactoPage = () => {
  const [vendedoresData, setVendedoresData] = useState<VendedorProps[]>([]);

  useEffect(() => {
    let isSubscribed = true;

    axios
      .get<{
        data: {
          id: number;
          nombre: string;
          apellido: string;
          telefono: string;
        }[];
      }>("https://api.grupoaerotec.com.mx/getPhones.php?type=ventas")
      .then((res) => {
        const data = res.data.data;
        const newVendedoresData = data.map((vendedor) =>
          generateVendedor({
            name: `${vendedor.nombre} ${vendedor.apellido}`,
            title: "Agente de ventas",
            phone: formatPhoneNumber(vendedor.telefono),
          })
        );

        if (isSubscribed) setVendedoresData(newVendedoresData);
      })
      .catch((err) => {
        setVendedoresData([]);
      });

    return () => {
      isSubscribed = false;
    };
  }, []);

  return (
    <Layout navbar={{ staticColor: "blue", staticOpacity: "solid" }}>
      <section className="w-full h-auto">
        <div className="w-full h-full pt-20">
          <div className="w-11/12 mx-auto py-10">
            <div className="flex flex-wrap lg:items-center">
              <div className="w-10/12 mx-auto lg:w-5/12 ">
                <ContactoIll />
              </div>
              <div className="w-full lg:w-5/12">
                <div className="text-center my-6">
                  <h1 className="font-bold text-5xl sm:text-6xl mb-1 text-aerotec-dark-90">
                    Contáctanos
                  </h1>
                  <h3 className="font-semibold text-2xl text-aerotec-dark-80 mb-1">
                    ¡Cotiza tus productos!
                  </h3>
                  <p className="text-gray-800 font-medium text-lg">
                    Comunícate con nosotros y te haremos llegar la información
                    que deseas.
                  </p>
                </div>
                <div className="flex justify-center ">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-8">
        <div className="container">
          <div className="text-center my-16">
            <h2 className="text-aerotec-10 font-bold text-4xl sm:text-5xl mb-2">
              Contáctanos por otros medios
            </h2>
            <p className="text-base sm:text-lg font-semibold text-gray-900">
              ¿Necesitas atención personalizada?, puedes comunicarte con alguno
              de nuestros agentes de ventas por whatsapp
            </p>
          </div>
          <div className="flex flex-wrap mx-auto gap-4 sm:gap-8 justify-center my-10">
            {vendedoresData.map((vendedor, index) => (
              <Vendedor
                link={vendedor.link}
                name={vendedor.name}
                title={vendedor.title}
                phone={vendedor.phone}
                key={index}
              />
            ))}
          </div>
          <div>
            <div className="text-center mb-8">
              <h3 className="font-bold text-3xl mb-2">
                Medios de Comunicación Alternativos
              </h3>
              <p className="text-gray-800 text-lg">
                Comunícate con nosotros a través de nuestras líneas telefónicas
                o por medio de un correo electrónico.
              </p>
            </div>
            <div className="mb-8">
              <h4 className="font-bold text-2xl mb-2 text-center">
                Correo Electrónico
              </h4>
              <div className="flex justify-center">
                <ContactoLink
                  link="mailto:informes.aerotec@gmail.com"
                  contact="informes.aerotec@gmail.com"
                  icon={<FaEnvelope className="w-6 h-6" />}
                />
              </div>
            </div>
            <div>
              <h4 className="font-bold text-2xl mb-2 text-center">Teléfonos</h4>
              <div className="flex flex-wrap gap-8 justify-center">
                <ContactoLink
                  link="tel:5521585375"
                  contact="55 2158 5375"
                  icon={<MdSmartphone className="w-6 h-6" />}
                />
                <ContactoLink
                  link="tel:5557666144"
                  contact="55 57666 144"
                  icon={<MdSmartphone className="w-6 h-6" />}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-8 pt-20 h-auto sm:h-screen flex items-center">
        <div className="container flex flex-wrap">
          <div className="text-center flex-shrink flex-grow basis-full mb-8">
            <h2 className="font-bold">
              <span className="text-2xl">Solicita nuestro</span>
              <br />
              <b className="font-bold text-4xl sm:text-6xl">Catálogo Digital</b>
            </h2>
          </div>
          <div className="flex justify-center mb-6 basis-full lg:basis-5/12">
            <StaticImage
              src="../images/cat-png.png"
              alt="Catáglogo digital Aerotec Publicidad"
            />
          </div>
          <div className="flex items-center justify-center flex-grow flex-shrink basis-full lg:basis-5/12">
            <CatalogoForm />
          </div>
        </div>
      </section>
      <section className="my-8 pt-20 h-auto">
        <div className="container">
          <div className="mb-8 text-center">
            <h3 className="text-aerotec-dark-10 font-bold text-4xl md:text-6xl mb-2">
              ¡Síguenos en Nuestras Redes Sociales!
            </h3>
            <p className="text-gray-900 font-semibold text-2xl">
              Encuentranos en:
            </p>
          </div>
          <div className="container flex justify-center gap-8 flex-wrap md:flex-nowrap">
            <div className="text-center basis-10/12">
              <Link
                to="https://www.facebook.com/AerotecPublicidad/"
                target="_blank"
                aria-label="Aerotec Publicidad en Facebook"
              >
                <StaticImage
                  src="../images/facebook-degradado.svg"
                  alt="Ícono de Facebook"
                  className="mb-4 block w-full mx-auto"
                />
              </Link>
              <span className="text-xl">@AerotecPublicidad</span>
            </div>
            <div className="text-center basis-10/12">
              <Link
                to="https://www.instagram.com/publicidadaerotec/"
                target="_blank"
                aria-label="Aerotec Publicidad en Instagram"
              >
                <StaticImage
                  src="../images/instagram-degradado.svg"
                  alt="ícono de instagram"
                  className="block mb-4 w-full mx-auto"
                />
              </Link>
              <span className="text-xl">@publicidadaerotec</span>
            </div>
            <div className="text-center basis-10/12">
              <Link
                to="https://www.tiktok.com/@aerotecpublicidad"
                target="_blank"
                aria-label="Aerotec Publicidad en TikTok"
              >
                <StaticImage
                  src="../images/tiktok-degradado.svg"
                  alt="icono tiktok"
                  className="block mb-4 w-full mx-auto"
                />
              </Link>
              <span className="text-xl">@aerotecpublicidad</span>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-20">
        <GrupoAerotecLogos />
      </section>
      <section className="mt-20">
        <div className="text-center">
          <p className="font-bold text-5xl md:text-6xl mb-6 text-aerotec-10">
            Estamos ubicados en:
          </p>
          <div className="w-full h-screen-4/6 md:h-screen-2/3">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3760.6687458457104!2d-99.05142928468273!3d19.512882443163875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fa0db33a6bb1%3A0xcab7e5487fdfc58a!2sAEROTEC%20PUBLICIDAD%3A%20Inflables%20Publicitarios!5e0!3m2!1ses-419!2smx!4v1653658345932!5m2!1ses-419!2smx"
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen={true}
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactoPage;
