import React from "react";
import { useCopyButton } from "../../../hooks/useCopyButton";
import { Notification } from "../../layout/Notification";
import Link from "../../lib/customLink";
import { ContactoLinkProps } from "./types";

export const ContactoLink = (props: ContactoLinkProps) => {
  const [, Icon] = useCopyButton(props.contact);

  return (
    <div className="bg-slate-50 px-8 py-4 flex gap-4 justify-center items-center w-11/12 lg:w-5/12 rounded-lg">
      <Link
        to={props.link}
        className="flex items-center transition-colors duration-150 hover:text-aerotec-dark-10"
        aria-label={`Contactar con ${props.contact}`}
      >
        <span className="link-icon mr-2 contact-button-icon">{props.icon}</span>
        <span className="text-sm sm:text-lg">{props.contact}</span>
      </Link>
      <div className="transition-colors duration-150 hover:text-aerotec-dark-10 contact-button-icon">
        {Icon}
      </div>
    </div>
  );
};
