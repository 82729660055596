import React, { ReactNode } from "react";
import Portal from "./Modal";

type NotificationProps = {
  message: ReactNode;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  clickable?: boolean;
};

export function Notification({
  message,
  onClick,
  clickable = false,
  ...rest
}: NotificationProps &
  React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLSpanElement>,
    HTMLSpanElement
  >) {
  return (
    <Portal
      overlay="transparent"
      className={`${
        clickable ? "cursor-pointer" : ""
      } absolute bottom-10 bg-gray-200 px-10 py-2 rounded-lg backdrop-blur bg-opacity-25`}
      onClick={onClick}
    >
      <span {...rest}>{message}</span>
    </Portal>
  );
}
