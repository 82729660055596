import gsap from "gsap";
import React, { ReactNode, useEffect } from "react";
import { FaRegCheckCircle } from "react-icons/fa";

type FormSuccessAlertProps = {
  title: ReactNode | string;
  message: ReactNode | string;
};

export const FormSuccessAlert = (props: FormSuccessAlertProps) => {
  useEffect(() => {
    const tl = gsap.timeline();

    setTimeout(() => {
      tl.fromTo(
        "#success-ill path",
        {
          ease: "power1.out",
          drawSVG: "10",
          fill: "transparent",
        },
        {
          duration: 2.5,
          fill: "#15803d",
          drawSVG: "100%",
        }
      );
    }, 10);
  }, []);

  return (
    <div className="">
      <div className="flex justify-center mb-4">
        <FaRegCheckCircle
          className="w-20 h-20 fill-green-700"
          id="success-ill"
        />
      </div>
      <span className="text-lg font-semibold text-center block mb-4">
        {props.title}
      </span>
      <p className="text-center text-sm">{props.message}</p>
    </div>
  );
};
