import { VendedorProps } from "../components/themed/contactPageComponents/types";
import { generateWhatsappLink } from "../helpers/generateWhatsappLink";

export function generateVendedor(data: {
  name: string;
  title: string;
  phone: string;
}): VendedorProps {
  return {
    name: data.name,
    title: data.title,
    phone: data.phone,
    link: generateWhatsappLink(data.phone, data.name),
  };
}

export const vendedores: VendedorProps[] = [
  generateVendedor({
    name: "Brenda Osorno",
    title: "Agente de ventas",
    phone: "55 5331 7075",
  }),
  generateVendedor({
    name: "Miroslaba Guillén",
    title: "Agente de ventas",
    phone: "55 5331 7076",
  }),
  generateVendedor({
    name: "Juliet Olivares",
    title: "Agente de ventas",
    phone: "55 8440 4382",
  }),
];
