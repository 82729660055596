import React from "react";
import { DivElement, IGenericImageData } from "../types";
import { removeExtensionFile } from "../../helpers/removeExtensionFile";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Link from "../lib/customLink";
import { webLinks } from "../../data/web-pages-link";

interface GrupoAerotecProps extends DivElement {}

const GrupoAerotecLogos = ({ className, ...rest }: GrupoAerotecProps) => {
  const logosData = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativeDirectory: { eq: "logos-marcas" }
          extension: { regex: "/(jpg)|(png)|(webp)|(jpeg)/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(height: 100, quality: 9)
            }
            id
            base
          }
        }
      }
    }
  `) as IGenericImageData;

  return (
    <div className={`bg-white pb-20 ${className || ""} `} {...rest}>
      <div className="flex container flex-wrap lg:flex-nowrap lg:gap-6 gap-8 justify-center">
        {logosData.allFile.edges.map((image) => {
          const img = getImage(image.node.childImageSharp.gatsbyImageData);
          const alt = removeExtensionFile(image.node.base);
          const linkButton = webLinks[alt];

          return (
            <div className="" key={image.node.id}>
              <Link
                to={linkButton}
                target="_blank"
                aria-label={`Visiar ${alt}`}
              >
                {img ? (
                  <GatsbyImage image={img} alt={alt} />
                ) : (
                  "No se pudo cargar la imágen"
                )}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default GrupoAerotecLogos;
