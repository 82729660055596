// extracted by mini-css-extract-plugin
export var cls1 = "contacto-ill-module--cls-1--kbsbG";
export var cls10 = "contacto-ill-module--cls-10--d-uJj";
export var cls11 = "contacto-ill-module--cls-11--AtaWg";
export var cls2 = "contacto-ill-module--cls-2--IjTXY";
export var cls3 = "contacto-ill-module--cls-3--prJvG";
export var cls4 = "contacto-ill-module--cls-4--+GC8r";
export var cls5 = "contacto-ill-module--cls-5--Zxx3Y";
export var cls6 = "contacto-ill-module--cls-6--DO8vd";
export var cls7 = "contacto-ill-module--cls-7--Okn6g";
export var cls8 = "contacto-ill-module--cls-8--RWS9V";
export var cls9 = "contacto-ill-module--cls-9--54kyN";