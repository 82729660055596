import React, { useMemo, useRef } from "react";
import { MdOutlineContentCopy } from "react-icons/md";

export function useCopyButton(
  data: string
): [
  Button: JSX.Element,
  CopyIcon: JSX.Element,
  ButtonRef: React.RefObject<HTMLButtonElement>,
  showNotification: boolean
] {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [showNotification, setShowNotification] = React.useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(data);
    buttonRef.current?.classList.add("copied");

    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 1200);
  };

  const CopyIcon = useMemo(() => {
    return (
      <button onClick={handleCopy}>
        <MdOutlineContentCopy className="w-6 h-6" />
      </button>
    );
  }, [data]);

  const CopyButton = useMemo(() => {
    return (
      <button
        onClick={handleCopy}
        className="flex justify-center items-center mx-auto mb-2 hover:text-gray-900 transition-colors duration-150"
      >
        <MdOutlineContentCopy className="mr-1 fill-slate-600 h-6 w-6" /> {data}
      </button>
    );
  }, [data]);


  return [
    CopyButton,
    CopyIcon,
    buttonRef,
    showNotification,
  ];
}
