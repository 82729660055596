import { Form, Formik, useFormik } from "formik";
import React from "react";
import { FaEnvelope, FaUserCircle, FaWhatsapp } from "react-icons/fa";
import { object, string } from "yup";
import { submitForm } from "../../helpers/sumbitForm";
import Portal from "../layout/Modal";
import { FormSuccessAlert } from "./FormSuccessAlert";
import { InteractiveSumbitButton } from "./interactiveSubmitButton";
import { ThemedFormField } from "./themedFormField";

const CatalogoForm = () => {
  const ContactFormRef = React.createRef<HTMLFormElement>();
  const [showSuccessAlert, setShowSuccessAlert] = React.useState(false);

  const handleResetFormFields = () => {
    const fields = ContactFormRef.current?.querySelectorAll(".form-field");

    fields?.forEach((field) => {
      field
        .querySelectorAll(
          ".valid, .active, .error, .active-color, .active-position"
        )
        .forEach((el) =>
          el.classList.remove(
            "valid",
            "active",
            "error",
            "active-color",
            "active-position"
          )
        );
    });
  };

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        phone: "",
      }}
      validationSchema={object({
        name: string().required("El nombre es requerido"),
        email: string()
          .email("El email no es válido")
          .required("El email es requerido"),
        phone: string()
          .min(10, "El whatsapp debe tener 10 dígitos")
          .max(10, "El whatsapp no debe tener más de 10 dígitos")
          .required("El whatsapp es requerido"),
      })}
      onSubmit={async (values, props) => {
        try {
          const formErrors = await props.validateForm(values);
          const fields = Object.values(formErrors);
          const isValid = fields.every((field) => field.length === 0);

          if (isValid) {
            props.setSubmitting(true);
            const response = await submitForm(values, "catalogo");
            if (response.data.wasSended) {
              handleResetFormFields();
              props.resetForm();
              setShowSuccessAlert(true);
              setTimeout(() => {
                setShowSuccessAlert(false);
              }, 1800);
            } else {
              console.error("Ha ocurrido un error al enviar el mensaje");
            }
            props.setSubmitting(false);
          }
        } catch (error) {
          console.error(error);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className="form w-11/12" ref={ContactFormRef}>
            <ThemedFormField
              label="Nombre*"
              name="name"
              type="text"
              icon={<FaUserCircle className="h-8" />}
            />
            <ThemedFormField
              label="Correo Electrónico*"
              name="email"
              type="email"
              icon={<FaEnvelope className="h-8" />}
            />
            <ThemedFormField
              label="Whatsapp*"
              name="phone"
              type="tel"
              icon={<FaWhatsapp className="h-8" />}
            />
            <div className="w-full my-8">
              <InteractiveSumbitButton
                isSubmitting={isSubmitting}
                showSuccess={showSuccessAlert}
              />
            </div>
            {showSuccessAlert && (
              <Portal className="bg-white p-10 bottom-10 rounded-lg shadow w-10/12 mx-auto md:w-auto">
                <FormSuccessAlert
                  title="¡Listo!"
                  message="Te hemos enviado el catalogo"
                />
              </Portal>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default CatalogoForm;
