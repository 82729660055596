import React from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useCopyButton } from "../../../hooks/useCopyButton";
import { Notification } from "../../layout/Notification";
import Link from "../../lib/customLink";
import { VendedorProps } from "./types";

export const Vendedor = (props: VendedorProps) => {
  const [Button, , , showNotification] = useCopyButton(props.phone);

  return (
    <div className="bg-slate-50 mb-5 py-6 rounded-lg w-full sm:w-5/12">
      <div className="mb-4">
        <p className="font-bold text-xl sm:text-2xl text-center">
          {props.name}
        </p>
        <span className="block text-center text-sm sm:text-base font-semibold">
          {props.title}
        </span>
      </div>

      {Button}

      {showNotification && <Notification message="Copiado al portapapeles" />}

      <Link
        to={props.link}
        className="bg-green-600 flex w-6/12 mx-auto items-center justify-center px-4 py-2 rounded-lg text-white hover:bg-green-700 transition-colors duration-150 text-sm mt-8 whitespace-nowrap"
        target="_blank"
        aria-label={`Contactar con ${props.name}`}
      >
        <FaWhatsapp className="mr-1 h-6" />
        <span className="">Contactar ahora</span>
      </Link>
    </div>
  );
};
